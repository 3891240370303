


import FETCHING from '../../library/fetching'
import DATA_MASTER from '../../library/dataMaster'
import UMUM from '../../library/umum'

export default {
  data() {
    return {

      form : {
        id : '',
        judul : '',
        sumber : '',
        deskripsi : '',
        isi : '',
        file : null,
        file_old : '',
      },

      list_data : [],

      cari_value: "",
      page_first: 1,
      page_last: 0,
      page_limit : 10,

      page_first1: 1,
      page_last1: 0,
      page_limit1 : 10,
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat : false,
      mdl_foto : false,
      btn_add: false,


      FETCHING : FETCHING,
      DATA_MASTER : DATA_MASTER,
      UMUM : UMUM,

      file_old : "",
      file_path: this.$store.state.url.URL_APP + "uploads/",
    }
  },
  methods: {


    getView : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_BERITA + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              page_limit: this.page_limit,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.cek_load_data = false;
            //   console.log(res_data);
      });
    },

    addData : function() {
        var formData = new FormData();
        formData.append("judul", this.form.judul);
        formData.append("sumber", this.form.sumber);
        formData.append("deskripsi", this.form.deskripsi);
        formData.append("isi", this.form.isi);
        formData.append("file", this.form.file);

        fetch(this.$store.state.url.URL_BERITA + "addData", {
          method: "POST",
          headers: {
            authorization: "kikensbatara " + localStorage.token
          },
           body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      var formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("judul", this.form.judul);
      formData.append("sumber", this.form.sumber);
      formData.append("deskripsi", this.form.deskripsi);
      formData.append("isi", this.form.isi);
      formData.append("file", this.form.file);
      formData.append("file_old", this.file_old);

      fetch(this.$store.state.url.URL_BERITA + "editData", {
        method: "POST",
        headers: {
          authorization: "kikensbatara " + localStorage.token
        },
        body: formData
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(idnya, file){
      fetch(this.$store.state.url.URL_BERITA + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            id : idnya,
            file : file, 
          })
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){
        this.form.id = data.id;
        this.form.judul = data.judul;
        this.form.sumber = data.sumber;
        this.form.deskripsi = data.deskripsi;
        this.form.isi = data.isi;
        this.form.file = data.file;
        this.file_old = data.file;
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },
        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },


  },

  mounted () {
    this.getView();
  },
}
